import React from "react";

import "./About.css";
import portrait from "../AF.jpg";

const About = () => {
    return (
        <div className="about-grid bg-white text-black about-scroll-to" id="home-content">
            <div className="about-grid-header">
                <h1 className="caps font-32 title-underline">About</h1>
            </div>
            <div className="about-grid-image">
                <img src={portrait} className="image" alt="Adam Foot Portrait"/>
            </div>
            <div className="about-grid-content">
                <p>I am a hard-working Front-End developer with a passion for creating UI's which are not only beautiful
                    but functional. I have experience with creating UIs for Android applications (Java), websites and
                    desktop applications. My primary focus is on websites as I love learning CSS and
                    JavaScript, with hopes that one day I can pursue a career which makes use of both of these languages.</p>
                <p>I also have experience with some back-end technologies which include NodeJS and SQL, alongside some
                    SaaS platforms such as Google Cloud Platform and Google's Firebase.</p>
            </div>
        </div>
    );
}

export default About;