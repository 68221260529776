import React, {Component} from "react";

import "./Nav.css";

import {scroller} from "react-scroll";

class Nav extends Component {

    scrollToHome = () => {
        scroller.scrollTo("landing-scroll-to", {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart"
        });
    }

    scrollToAbout = () => {
        scroller.scrollTo("about-scroll-to", {
            duration: 800,
            offset: -77,
            delay: 0,
            smooth: "easeInOutQuart"
        });
    }

    scrollToProjects = () => {
        scroller.scrollTo("project-scroll-to", {
            duration: 800,
            offset: -77,
            delay: 0,
            smooth: "easeInOutQuart"
        });
    }

    scrollToContact = () => {
        scroller.scrollTo("contact-scroll-to", {
            duration: 800,
            offset: -77,
            delay: 0,
            smooth: "easeInOutQuart"
        });
    }

    render() {
        return (
            <nav className="navbar sticky-nav home-scroll-to">
                <ul>
                    <li><p onClick={this.scrollToHome}>Home</p></li>
                    <li><p onClick={this.scrollToAbout}>About</p></li>
                    <li><p onClick={this.scrollToProjects}>Projects</p></li>
                    <li><p onClick={this.scrollToContact}>Contact</p></li>
                </ul>
            </nav>
        );
    }
}

export default Nav;