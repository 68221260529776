import React, {Component} from "react";

import "./Projects.css";

class Projects extends Component {

    componentDidMount() {
        // Modal Start //
        let wzstats_modal = document.getElementById("wzstats-modal");
        let slw_modal = document.getElementById("slw-modal");
        let tts_modal = document.getElementById("tts-modal");
        let afxnh_modal = document.getElementById("afxnh-modal");
        let gr_modal = document.getElementById("gr-modal");
        let ahr_modal = document.getElementById("ahr-modal");
        let cmail_modal = document.getElementById("cmail-modal");

        let wzstats_project = document.getElementById("wzstats-modal-btn");
        let slw_project = document.getElementById("slw-modal-btn");
        let tts_project = document.getElementById("tts-modal-btn");
        let afxnh_project = document.getElementById("afxnh-modal-btn");
        let gr_project = document.getElementById("gr-modal-btn");
        let ahr_project = document.getElementById("ahr-modal-btn");
        let cmail_project = document.getElementById("cmail-modal-btn");

        wzstats_project.onclick = function () {
            wzstats_modal.style.display = "block";
        }
        slw_project.onclick = function () {
            slw_modal.style.display = "block";
        }
        tts_project.onclick = function () {
            tts_modal.style.display = "block";
        }
        afxnh_project.onclick = function () {
            afxnh_modal.style.display = "block";
        }
        gr_project.onclick = function () {
            gr_modal.style.display = "block";
        }
        ahr_project.onclick = function () {
            ahr_modal.style.display = "block";
        }
        cmail_project.onclick = function () {
            cmail_modal.style.display = "block";
        }

        window.onclick = function (event) {
            if (event.target === wzstats_modal) {
                wzstats_modal.style.display = "none";
            } else if (event.target === slw_modal) {
                slw_modal.style.display = "none";
            } else if (event.target === tts_modal) {
                tts_modal.style.display = "none";
            } else if (event.target === afxnh_modal) {
                afxnh_modal.style.display = "none";
            } else if (event.target === gr_modal) {
                gr_modal.style.display = "none";
            } else if (event.target === ahr_modal) {
                ahr_modal.style.display = "none";
            } else if (event.target === cmail_modal) {
                cmail_modal.style.display = "none";
            }
        }
        // Modal End //
    }

    render() {
        return (
            <div className="project-grid bg-white text-black project-scroll-to" id="home-content">
                <div className="project-grid-header">
                    <h1 className="caps font-32 title-underline">Projects</h1>
                </div>
                <div className="project-grid-1" id="wzstats-modal-btn">
                    <h2>ATN3's WZ Stats</h2>
                </div>
                <div className="project-grid-2" id="slw-modal-btn">
                    <h2>Shannon Leah Watson</h2>
                </div>
                <div className="project-grid-3" id="tts-modal-btn">
                    <h2>AF's Speech-to-Text</h2>
                </div>
                <div className="project-grid-4" id="afxnh-modal-btn">
                    <h2>AF x NH</h2>
                </div>
                <div className="project-grid-5" id="gr-modal-btn">
                    <h2>Games Review</h2>
                </div>
                <div className="project-grid-6" id="ahr-modal-btn">
                    <h2>Angular Heroes Project</h2>
                </div>
                <div className="project-grid-7" id="cmail-modal-btn">
                    <h2>Colour Mail</h2>
                </div>

                {/* Modals */}
                <div id="wzstats-modal" className="modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2>ATN3s WZ Stats</h2>
                        </div>
                        <div className="modal-body">
                            <p>This project was created to keep track of certain stats for the popular online game Call
                                of Duty: Warzone.</p>
                            <p>It fetches data via an API using a Firebase Cloud Function. The function is set to run
                                every 30 minutes to update the stats to it's most recent data via a GCP cron job. The
                                data is then stored into a Firebase Database which is used to render the relevant data
                                to the page.</p>
                            <p>This website makes use of the following technologies:</p>
                            <ul>
                                <li>ReactJS</li>
                                <li>CSS</li>
                                <li>Bootstrap</li>
                                <li>Google Cloud Platform (Cron job for updating data every 30 minutes)</li>
                                <li>Call of Duty API (https://github.com/Lierrmm/Node-CallOfDuty)</li>
                                <li>Firebase (Hosting, Firestore Database, Functions)</li>
                            </ul>
                            <p>*Please note: This website is no longer maintained as of June 2021. Changes to the API
                                over time may break the website.*</p>
                        </div>
                        <div className="modal-footer">
                            <div>
                                <a href="https://atn3-s-warzone-s.web.app/" target="_blank" rel="noreferrer">View
                                    Website</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="slw-modal" className="modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2>Shannon Leah Watson</h2>
                        </div>
                        <div className="modal-body">
                            <p>This project was created to help the artist Shannon Watson showcase all of her
                                contemporary art work.</p>
                            <p>Shannon designed the website to how she would like and is very pleased with the outcome.
                                I regularly maintain the website and add new projects when Shannon would like me to.</p>
                            <p>This website makes use of the following technologies:</p>
                            <ul>
                                <li>ReactJS</li>
                                <li>CSS</li>
                                <li>Bootstrap</li>
                                <li>Firebase (Hosting)</li>
                            </ul>
                        </div>
                        <div className="modal-footer">
                            <div>
                                <a href="https://shannonleahwatson.co.uk/" target="_blank" rel="noreferrer">View
                                    Website</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="tts-modal" className="modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2>Speech-to-Text Webpage</h2>
                        </div>
                        <div className="modal-body">
                            <p>This project was created to help a fellow student who struggled with typing using a
                                keyboard.</p>
                            <p>They were supplied with a headset and some speech transcription software but found it to
                                be unreliable.I decided to build a very simple yet effective website that allowed you to
                                speak into a microphone and text would appear in a textarea to be copied.</p>
                            <p>This website makes use of the following technologies:</p>
                            <ul>
                                <li>HTML & JavaScript</li>
                                <li>Bootstrap</li>
                                <li>Web Speech API (SpeechRecognition)</li>
                                <li>Firebase (Hosting)</li>
                            </ul>
                        </div>
                        <div className="modal-footer">
                            <div>
                                <a href="https://aftts-91e86.firebaseapp.com/" target="_blank" rel="noreferrer">View
                                    Website</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="afxnh-modal" className="modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2>AFxNH</h2>
                        </div>
                        <div className="modal-body">
                            <p>This project was created in collaboration with my best friend.</p>
                            <p>We wanted to create a website that could advertise some services which we could offer to
                                customers. As we enjoy making and taking apart PC's we decided to get a page up and
                                running where we could offer bespoke PC cases for use by gamers or professionals. We
                                have long spoken about designing and manufacturing custom PC cases for ourselves so
                                mocked up a website to see how it would look if we were to offer this service to the
                                public. We researched payment options (Stripe, PayPal) and different databases to store
                                customer and product data.</p>
                            <p>This website makes use of the following technologies:</p>
                            <ul>
                                <li>ReactJS</li>
                                <li>CSS</li>
                                <li>Bootstrap</li>
                                <li>Firebase (Hosting)</li>
                            </ul>
                        </div>
                        <div className="modal-footer">
                            <div>
                                <a href="https://afxnh-78b4c.web.app/" target="_blank" rel="noreferrer">View
                                    Website</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="gr-modal" className="modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2>Games Review</h2>
                        </div>
                        <div className="modal-body">
                            <p>This project was created to practice some Angular development.</p>
                            <p>I wanted to take a brief deep-dive into Angular so decided to make a simple webpage that
                                made use of Angular and a Firestore database. The page allows the user to type in a
                                video game name, give it a genre and then rank the game out of 5. The details are then
                                saved into the database and rendered to the same page beside it. It supports addition
                                and deletion of data.</p>
                            <p>This website makes use of the following technologies:</p>
                            <ul>
                                <li>Angular</li>
                                <li>Bootstrap</li>
                                <li>Firebase (Hosting, Firestore)</li>
                            </ul>
                        </div>
                        <div className="modal-footer">
                            <div>
                                <a href="https://gamesreview-6e2f4.web.app/" target="_blank" rel="noreferrer">View
                                    Website</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="ahr-modal" className="modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2>Angular Heroes</h2>
                        </div>
                        <div className="modal-body">
                            <p>This project was created to practice some Angular development.</p>
                            <p>I created this project to grasp the use case of state within Angular. It allows the user
                                to search for specific Marvel and DC superheroes that are stored in a pre-populated list
                                within the state. It also allows users to edit and add new heroes if they want. These
                                changes are only kept within the session due to the nature of state and not storing the
                                data in a database.</p>
                            <p>This website makes use of the following technologies:</p>
                            <ul>
                                <li>Angular</li>
                                <li>Bootstrap</li>
                                <li>Firebase (Hosting)</li>
                            </ul>
                        </div>
                        <div className="modal-footer">
                            <div>
                                <a href="https://angulartest-43543.web.app" target="_blank" rel="noreferrer">View
                                    Website</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="cmail-modal" className="modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2>Colour Mail Extension</h2>
                        </div>
                        <div className="modal-body">
                            <p>This browser extension was created to help a student who struggled with contrasting
                                emails.</p>
                            <p>I knew a student who would struggle to read and write emails on a computer if the colours
                                were
                                too bright and contrasting. To solve this I created a browser extension that allows the
                                user to press a key combination and it would turn all background elements on a n outlook
                                email
                                to a dark blue/grey. This would allow the email to be more readable and easier on the
                                eyes.</p>
                            <p>This extension makes use of the following technologies:</p>
                            <ul>
                                <li>JavaScript</li>
                            </ul>
                        </div>
                        <div className="modal-footer">
                            <div>
                                <a href="https://chrome.google.com/webstore/detail/colourmail/aaghgjfphjnnamaoadafmijgmgnnpglo"
                                   target="_blank" rel="noreferrer">View
                                    Extension</a>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}

export default Projects;