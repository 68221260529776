import React from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";

// Component Imports
import Landing from './components/Landing';

import * as ROUTES from './constants/Routes';

const App = () => {
    return (
        <Router>
            <Route exact path={ROUTES.LANDING} component={Landing} />
        </Router>
    );
}

export default App;