import {useState} from "react";
import emailjs from "@emailjs/browser";

import "./Contact.css";

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const validate = () => {
        return name.length & email.length & message.length;
    };

    function submitForm(e) {
        e.preventDefault();

        // Submit Form
        sendEmail();

        // Reset Form
        setName('');
        setEmail('');
        setMessage('');
    }

    function sendEmail() {
        const params = {
            from_name: name, email_address: email, message: message
        }

        emailjs.send('service_o4bqzan', 'template_ozx6rly', params, 'qlzzy2kt7cydL6-3O')
            .then(() => {
                setError('Your message has been sent successfully!');
            }, (err) => {
                setError(err.message);
            });
    }


    return (
        <div className="contact-grid bg-white text-black contact-scroll-to" id="home-content">
            <div className="contact-grid-header">
                <h1 className="caps font-32 title-underline">Contact</h1>
            </div>
            <div className="contact-grid-form">
                <form onSubmit={submitForm} className="form-style">
                    <h3>Want to get in touch with me?</h3>
                    <p>Fill out the form below!</p>

                    <div className="custom-form-body text-start">
                        <div>
                            <label htmlFor="name">Name</label>
                            <input type="text" name="name" id="name" value={name}
                                   onChange={(e) => setName(e.target.value)}/>
                        </div>
                        <div>
                            <label htmlFor="email">Email Address</label>
                            <input type="email" name="email" id="email" value={email}
                                   onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                        <div>
                            <label htmlFor="message">Message</label>
                            <textarea name="message" id="message" value={message}
                                      onChange={(e) => setMessage(e.target.value)}/>
                        </div>
                    </div>

                    <button type="button" className={!validate() ? 'button-disabled' : 'button-style'} disabled={!validate()}>Submit</button>
                    <p className="form-message">{error}</p>
                </form>
            </div>
        </div>
    );
}

export default Contact;