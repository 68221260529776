import React from "react";
import {scroller} from "react-scroll";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons';
import {faFreeCodeCamp, faGithubSquare, faLinkedin} from "@fortawesome/free-brands-svg-icons";

import Particles from "react-tsparticles";

import Nav from "./nav/Nav";
import About from "./about/About";
import Projects from "./projects/Projects";
import Contact from "./contact/Contact";

import "./Landing.css";

function Landing() {


    const scrollToHome = () => {
        scroller.scrollTo("home-scroll-to", {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart"
        });
    }
        return (
            <>
                <div className="wrapper landing-scroll-to">
                    <Particles
                        options={{
                            "fullScreen": {
                                "enable": true,
                                "zIndex": -1
                            },
                            "fpsLimit": 60,
                            "particles": {
                                "number": {
                                    "value": 40,
                                    "density": {
                                        "enable": true,
                                        "value_area": 800
                                    }
                                },
                                "color": {
                                    "value": "#32a852",
                                    "animation": {
                                        "enable": false,
                                        "speed": 2,
                                        "sync": true
                                    }
                                },
                                "opacity": {
                                    "value": 0.5
                                },
                                "size": {
                                    "value": {
                                        "min": 0.1,
                                        "max": 3
                                    }
                                },
                                "links": {
                                    "enable": true,
                                    "distance": 100,
                                    "color": "#ffffff",
                                    "opacity": 0.4,
                                    "width": 2
                                },
                                "move": {
                                    "enable": true,
                                    "speed": 3,
                                    "direction": "none",
                                    "outModes": {
                                        "default": "out"
                                    }
                                }
                            },
                            "detectRetina": true,
                            "background": {
                                "color": "#232424"
                            }
                        }}/>
                    <div className="landing-home">
                        <h1 className="mb-0">Hi, my name is <a href="https://www.linkedin.com/in/adamfoot/" target="_blank" rel="noreferrer"><span className="text-teal name-animation">Adam Foot</span></a>.</h1>
                        <h1 className="mb-0">I am a Front-End Developer.</h1>
                        <button className="btn" onClick={scrollToHome}>View more <span><FontAwesomeIcon icon={faAngleDoubleRight}
                                                                                                        className="rotate-right"/></span></button>
                    </div>
                </div>

                <Nav />
                <About />
                <Projects />
                <Contact />

                <footer>
                    <div className="home">
                        <a href="https://www.freecodecamp.org/adam-foot" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faFreeCodeCamp} size="3x" className="margin-x-3 font-icon"/></a>
                        <a href="https://www.linkedin.com/in/adamfoot/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} size="3x" className="margin-x-3 font-icon"/></a>
                        <a href="https://github.com/Adam-Foot" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faGithubSquare} size="3x" className="margin-x-3 font-icon"/></a>
                    </div>
                    <div className="home">
                        <p>Copyright &copy; <span className="text-green">Adam Foot</span> 2022</p>
                    </div>
                </footer>
            </>
        );
}

export default Landing;